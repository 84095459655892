import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'gatsby';

import logoDefault from '../images/hospitaalbroeders-logo.svg';

const Transition = styled.div``;

const FixedHeaderBodyStyles = createGlobalStyle`
  body {
    margin-top: 6.5rem;

    ${({ theme }) => theme.media.tablet`
      margin-top: 8.25rem;
    `}
  }
`;

const FixedHeader = styled.header`
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

  &.visible {
    visibility: visible;
    transition: all 200ms ease-in;
  }

  &.hidden {
    visibility: hidden;
    transition: all 200ms ease-out;
    transform: translate(0, -100%);
  }
`;

const HeaderContainer = styled.div`
  margin: 0 auto;
  max-width: 80rem;
  text-align: center;

  ${({ theme }) => theme.media.tablet`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const Logo = styled.h1`
  display: inline-block;
  margin: 0;
  padding: 1rem;

  a {
    display: block;
    line-height: 0;
  }

  img {
    height: 4.5rem;
  }

  ${({ theme }) => theme.media.tablet`
    padding: 1rem 1.5rem;

    img {
      height: 6.25rem;
    }
  `}
`;

const ChildrenContainer = styled.div``;

const hasWindow = typeof window !== 'undefined' && window;
const hasDocument = typeof document !== 'undefined' && document;

const Header = ({
  children,
  siteTitle,
  logoIsVisible,
  logo,
  logoLink = '/'
}) => {
  const [scrolled, setScrolled] = useState(false);
  const [scrollY, setScrollY] = useState(hasWindow ? window.scrollY : 0);
  const [scrollDelta, setScrollDelta] = useState(0);

  useEffect(() => {
    if (!hasWindow || !hasDocument) {
      return () => {};
    }

    const handleScroll = () => {
      setScrollDelta(scrollY - window.scrollY);
      setScrollY(window.scrollY);

      const isScrolled = scrollY > 128;

      if (isScrolled !== scrolled) {
        setScrolled(!scrolled);
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY]);

  return (
    <Transition>
      <FixedHeaderBodyStyles />
      <FixedHeader
        className={!scrolled || scrollDelta > 0 ? 'visible' : 'hidden'}
      >
        <HeaderContainer>
          {logoIsVisible && (
            <Logo>
              <Link to={logoLink}>
                <img src={logo || logoDefault} alt={siteTitle} />
              </Link>
            </Logo>
          )}
          <ChildrenContainer>{children}</ChildrenContainer>
        </HeaderContainer>
      </FixedHeader>
    </Transition>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  siteTitle: PropTypes.string,
  logoIsVisible: PropTypes.bool,
  logo: PropTypes.string,
  logoLink: PropTypes.string
};

Header.defaultProps = {
  children: undefined,
  siteTitle: '',
  logoIsVisible: true
};

export default Header;
